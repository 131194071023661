<template>
  <a-modal v-model="visible" title="添加预习内容"
           :mask-closable="false" @ok="handleOk">
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      @submit="handleOk"
      v-bind="formItemLayout"
    >
      <a-form-item label="内容类型">
        <a-select
          placeholder="请选择"
          class="w700"
          v-decorator="['type', validatorRules.type]"
          @change="changeType"
        >
          <a-select-option
            v-for="item in selectList"
            :value="item.value"
            :key="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="名称">
        <a-select
          show-search
          placeholder="请选择"
          v-decorator="['res_id', validatorRules.res_id]"
          option-filter-prop="children"
          style="width: 200px"
          :filter-option="filterOption"
        >
          <a-select-option v-for="d in data" :key="d.id">
            {{ d.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { addPreviewContents, previewContentsSelectList } from '@/api/config';

export default {
  name: 'addPreviewContentLayer',
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      selectList: [
        {
          label: '课程',
          value: '1',
        },
        {
          label: '任务',
          value: '2',
        },
        {
          label: '话术',
          value: '3',
        },
      ],
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      validatorRules: {
        type: {
          rules: [{ required: true, message: '请选择内容类型!' }],
        },
        res_id: {
          rules: [{ required: true, message: '请选择名称!' }],
        },
      },
      data: [],
      post_id: '',
    };
  },
  methods: {
    openMe(post_id) {
      this.post_id = post_id;
      this.visible = true;
      this.setDefaultData();
    },
    handleOk(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.training_id = this.$route.query.id;
          values.post_id = this.post_id;
          this.save(values);
        }
      });
    },
    async save(params) {
      this.loading = true;
      const data = await addPreviewContents(params).finally(
        () => (this.loading = false),
      );

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success({ content: data?.message || '保存成功' });
      this.$emit('success');
      this.form.setFieldsValue({});
      this.visible = false;
    },
    setDefaultData() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          type: undefined,
          res_id: undefined,
        });
      });
    },
    changeType(v) {
      this.getSelectList(v);
    },
    async getSelectList(id) {
      this.loading = true;
      const data = await previewContentsSelectList({
        type: id,
        post_id: this.post_id,
      }).finally(() => (this.loading = false));
      if (!data || data.error_code) {
        return;
      }
      this.data = data.data;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

<style scoped>
</style>
